import { createFeature, createReducer, on } from '@ngrx/store';
import { PermissionActions } from './permission.actions';
import { User_Permission } from 'src/app/shared/models';

export const permissionFeatureKey = 'permission';

export interface PermissionState {
  permissions: User_Permission[];
  permission: User_Permission | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: PermissionState = {
  permissions: [],
  permission: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    PermissionActions.loadPermissions,
    (state): PermissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PermissionActions.loadPermissionsSuccess,
    (state, { permissions }): PermissionState => ({
      ...state,
      permissions: [
        ...state.permissions.filter(
          x => !permissions.find(y => x.user?.id && x.user.id === y.user?.id)
        ),
        ...permissions,
      ],
      loading: false,
      error: null,
    })
  ),
  on(
    PermissionActions.loadPermissionsFailure,
    (state, { error }): PermissionState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    PermissionActions.loadPermission,
    (state): PermissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PermissionActions.loadPermissionSuccess,
    (state, { permission }): PermissionState => ({
      ...state,
      permissions: [
        ...state.permissions.filter(
          x => x.user?.id && x.user.id !== x.user?.id
        ),
        permission,
      ],
      permission: permission,
      loading: false,
      error: null,
    })
  ),
  on(
    PermissionActions.loadPermissionFailure,
    (state, { error }): PermissionState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  // on(
  //   PermissionActions.addPermission,
  //   (state): PermissionState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionActions.addPermissionSuccess,
  //   (state, { permission }): PermissionState => ({
  //     ...state,

  //     permissions: [...state.permissions, permission],

  //     loading: false,

  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionActions.addPermissionFailure,
  //   (state, { error }): PermissionState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   })
  // ),
  // on(
  //   PermissionActions.removePermission,
  //   (state): PermissionState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionActions.removePermissionSuccess,
  //   (state, { permissionId }): PermissionState => ({
  //     ...state,
  //     permissions: state.permissions.filter(
  //       permission => permission.id !== permissionId
  //     ),
  //     loading: false,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionActions.removePermissionFailure,
  //   (state, { error }): PermissionState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   })
  // ),
  on(
    PermissionActions.updatePermission,
    (state): PermissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PermissionActions.updatePermissionSuccess,
    (state, { permission }): PermissionState => ({
      ...state,
      permissions: state.permissions.map(item =>
        // item.id === permission.id ? permission : item
        permission.user && item.user?.id === permission.user?.id
          ? { ...item, ...permission }
          : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    PermissionActions.updatePermissionFailure,
    (state, { error }): PermissionState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const permissionFeature = createFeature({
  name: permissionFeatureKey,
  reducer,
});
